import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Header = () => (
  <header className="menu-version-1 text-center">
		<Link to="/" >
			<StaticImage src="../images/logo.png" quality={95} formats={["AUTO", "WEBP", "AVIF"]} alt="Semple" className="logo d-inline-block" />
		</Link>
  </header>
)

export default Header
