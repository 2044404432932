import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Menu from "../components/menu_simple"
import Layout from "../components/layout"
import Seo from "../components/seo"

import "../sass/app/menu_version_1.scss"
import "../sass/pages/home.scss"


const IndexPage = () => (
  <Layout>
		<Seo title="Home" />

		<Menu />

		<div className="home overflow-hidden">
			<div className="container-fluid section-1" style={{maxWidth: '1600px'}}>
				<div className="row">
					<div className="col-12 col-lg-6 mb-5 mb-lg-0">
						<div className="row align-items-center align-items-lg-start">
							<div className="col-12 col-md-6 mb-3 mb-md-0 order-lg-2">
								<StaticImage src="../images/phone-home-1.png" quality={100} formats={["AUTO", "WEBP", "AVIF"]} alt="Soy cliente"></StaticImage>
							</div>
							<div className="col-12 col-md-6 order-lg-1 pt-lg-5">
								<div className="text-center">
									<Link to="/cliente" className="btn btn-azul">Soy Cliente</Link>
								</div>
								<p>Consigue servicios en tu comunidad de forma simple y segura con sólo un click.</p>
								<p>Semple te ahorra tiempo y esfuerzo ubicando a la persona ideal para solucionar tu problema.</p>
								<p>Describe el servicio que necesitas, cuando y donde lo quieres, y en minutos elige la mejor oferta para ti, considerando calificación, precio, tiempo de atención y distancia.</p>
								<p>Semple es la nueva App de que soluciona tu día.</p>
							</div>
						</div>
					</div>
					<div className="col-12 col-lg-6 pt-lg-5">
						<div className="row align-items-center align-items-lg-start">
							<div className="col-12 col-md-6 order-1 order-md-2 order-lg-1 mb-3 mb-md-0">
								<StaticImage src="../images/phone-home-2.png" quality={100} formats={["AUTO", "WEBP", "AVIF"]} alt="Soy cliente"></StaticImage>
							</div>
							<div className="col-12 col-md-6 order-2 order-md-1 order-lg-2 pt-lg-5">
								<div className="text-center">
									<Link to="/proveedor" className="btn btn-azul">Soy Proveedor</Link>
								</div>
								<p>Ofrece tus servicios a las personas en la comunidad que necesitan de ti.</p>
								<p>Semple te enlaza con la gente que necesita de tus servicios de forma directa.</p>
								<p>Recibe ofertas de trabajos en tiempo real y ofrécele al cliente tu mejor propuesta de forma simple, rápida y sin cobro de comisiones. Tu negocio. Tus precios. Nosotros sólo te enlazamos.</p>
								<p>Semple es la nueva App en la que encuentras más trabajo.</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="bg-ondulacion">
				<div className="container-fluid section-2" style={{maxWidth: '1600px'}}>
					<div className="row justify-content-center">
						<div className="col-12 col-lg-8 col-xl-7 text-center">
							<p>Somos una comunidad que enlaza personas de forma simple y confiable que resuelve la problemática de conseguir servicios.</p>
							<p>Semple conecta a las personas de la comunidad para encontrar soluciones.</p>
							<p>Creamos una comunidad la cual es la que recomienda y califica a sus integrantes, generando confianza en el uso de la misma, motivando las ofertas de trabajos e incentivando la economía local.</p>
							<p className="mb-5">Semple, soluciones a mano.</p>

							<a href="mailto:info@semple.app">info@semple.app</a>
						</div>
					</div>
				</div>
			</div>
		</div>

  </Layout>
)

export default IndexPage
